var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-table",
          {
            ref: "grid",
            attrs: {
              title: _vm.title,
              tableId: "grid",
              columnSetting: false,
              isFullScreen: false,
              columns: _vm.grid.columns,
              filtering: false,
              data: _vm.grid.data,
              selection: "multiple",
              rowKey: "envAirDailyResultPreventiveHistoryId",
              isExcelDown: false,
              editable: _vm.editable,
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "추가", icon: "add" },
                          on: { btnClicked: _vm.addrow1 },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "삭제", icon: "remove" },
                          on: { btnClicked: _vm.removeRow1 },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "저장", icon: "save" },
                          on: { btnClicked: _vm.saveRow1 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }